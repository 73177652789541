import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { Webinars } from '@common/pages'

const WebinarsPage = () => {
  const { desktop, mobile } = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "webinars/banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1899) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        mobile: file(relativePath: { eq: "webinars/banner-m.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  const [webinars, setWebinars] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${process.env.API_URL}/webinars?eventId=475485`);
      setWebinars(await response.json());
    }

    fetchData();
  }, [])

  const speakers = [
    {
      name: 'Pam Sparrow',
      subtitle: 'HelloMD Nurse Practitioner',
      image: '/pam-sparrow.png',
      bio: `Pam has been a nurse for 17 years. She has worked at hospitals, private practices, within oncology, women's health, primary family care, and has helped educate patients and practitioners across various specialties. At HelloMD, Pam has been a cannabis practitioner since July 2020 and is a strong advocate for alternative therapies used alongside western medicine. She enjoys using symptom management skills to educate people about the uses of medicinal cannabis in conjunction with other therapies.`
    },
    // {
    //   name: 'Drew Campbell',
    //   subtitle: 'VP of Marketing at TGOD',
    //   image: '/drew-campbell.jpg',
    //   bio: `Drew leads Marketing efforts at TGOD for their four brands (TGOD, Highly Dutch, Ripple & Cruuzy) in domestic and international markets, ranging from product innovation through commercialization. He brings over 15 years of marketing experience on the agency and client side. He spent several years at FUSE Marketing Group where he managed accounts for clients such as Canadian Tire, Sony, NASCAR and CIBC. He also worked at Boston Pizza, Canada’s largest casual dining chain, where he led the digital.`
    // },
    {
      name: 'Dr. Katia Tonkin, MBBS MD FRCP(UK) FRCPC',
      subtitle: 'Medical Oncologist & Board Member at Atlas Thrive',
      image: '/katia-tonkin.png',
      bio: `Dr. Tonkin is a medical oncologist who has worked at The Cross Cancer Institute in Edmonton Alberta Canada . She did her medical oncology training at The Princess Margaret Hospital in Toronto Ontario. Dr. Tonkin has 30 years of experience in clinical trials that specialized in breast and gynecological cancers. She started treating patients with cannabis in April 2017 and is interested in furthering cannabis education.`
    },
    // {
    //   name: 'Dr. Karolina Urban',
    //   subtitle: 'VP of Scientific & Medical Affairs @ Avicanna',
    //   image: '/karolina-urban.png',
    //   bio: `Dr. Karolina Urban is the Vice President of Medical and Scientific Affairs at Avicanna and leads Avicanna’s clinical partnerships and research collaborations. She received her MSc in Neurosciences at the University of Calgary, Dr. Urban has contributed to the development and execution of preclinical and clinical research projects at Avicanna.`
    // },
    /* {
      name: 'Pamela Hadfield',
      subtitle: 'Co-Founder HelloMD',
      image: '/pamela-hadfield.png',
      bio: `Pamela Hadfield is co-founder of HelloMD, North America's first digital healthcare and Telehealth platform for cannabis. She is a cannabis advocate and educator and enjoys talking about cannabis & complementary care.`
    } */
  ];

  const title = "Live Q&A - Finding Relief from Chronic Pain and Anxiety with Cannabis";
  const description = "Register now and join a live Q&A with our cannabis experts. Finding Relief from Chronic Pain and Anxiety with Cannabis";
  const image = "https://hellomd.ca/webinars-sdm-seo.jpg";

  return <Webinars webinars={webinars} desktopHeroImage={desktop} mobileHeroImage={mobile} showNextWebinar={true} speakers={speakers}
    title={title} description={description} image={image} />
}

export default WebinarsPage
